import React, { useMemo } from 'react';
import { ResponsiveLine } from '@nivo/line'
import { getDaysArray } from '../../Utilities/date';
import moment from 'moment'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export const fillData = (dateRange, processedData) => {
    const newData = processedData
    if (dateRange) {
        const startDate = moment(dateRange[0])
        const endDate = moment(dateRange[1])
        endDate.add(1, "day") // extra day is so the graph doesn't cut off early
        
        while (startDate.isSameOrBefore(endDate)) {
            newData[2].data.push({x: startDate.format("YYYY-MM-DD"), y: null})
            startDate.add(1, "day")
        }
    }
    return newData
}

export const fillMissingDays = (points, fillVal) => {
    const dates = Object.keys(points).sort();
    const datesFilled = getDaysArray(dates[0], dates.at(-1));
    const retVal = datesFilled.map((date) => {
        let yval = date in points ? points[date] : fillVal;
        return { x: date, y: yval };
    })
    return retVal;
};

const numberOfDays = (dateRange) => {
    if (!dateRange) return 10
    // get number of days in date range
    const start = moment(dateRange[0])
    const end = moment(dateRange[1])
    return end.diff(start, "days")
}

const PredictionsChart = ({ dateRange, nouhins, predictions, campaignData = [], events = [], forwardingOffPlans = [], tickValues, barChart }) => {
    
    let predictionsData = [];
    let historicalData = [];
    const dateSort = (a, b) => new Date(a) - new Date(b);

    if (predictions) {
        predictionsData = tickValues !== 'day' ? Object.keys(predictions).sort(dateSort).map(k => {
            return { x: k, y: predictions[k] }
        }) : fillMissingDays(predictions, 0);
    }

    if (nouhins) {
        historicalData = tickValues !== 'day' ? Object.keys(nouhins).sort(dateSort).map(k => {
            return { x: k, y: nouhins[k] }
        }) : fillMissingDays(nouhins, 0);
    }

    if (historicalData.length > 0) {
        predictionsData = predictionsData.filter( pred => pred.x >= historicalData.at(-1).x );
        historicalData.slice(0,-1).forEach(
            point => predictionsData.push( { x: point.x, y: null} )
        );
    }

    if (dateRange) {
        historicalData = historicalData.filter(object => {
            return dateRange[0] <= new Date(object.x) && dateRange[1] >= new Date(object.x)
        });
        predictionsData = predictionsData.filter(object => {
            return dateRange[0] <= new Date(object.x) && dateRange[1] >= new Date(object.x)
        });
    }
    
    const filledAndFilteredCampaigns = useMemo(() => {
        return campaignData.map(campaign => {
            const start = moment(campaign.startDt).format("YYYY-MM-DD")
            const end = moment(campaign.endDate).format("YYYY-MM-DD")
            const data = {}
            data[start] = 0
            data[end] = 0
            return {
                ...campaign,
                data: fillMissingDays(data, 0)
            }
        }).filter(campaign => moment(campaign.startDt) <= moment(dateRange[1]) && moment(campaign.endDate) >= moment(dateRange[0]))
    }, [campaignData, dateRange])

    const filledAndFilteredEvents = useMemo(() => {
        return events.map(event => {
            const start = moment(event.start_date).format("YYYY-MM-DD")
            const end = moment(event.end_date).format("YYYY-MM-DD")
            const data = {}
            data[start] = 0
            data[end] = 0
            return {
                ...event,
                data: fillMissingDays(data, 0)
            }
        }).filter(event => moment(event.start_date) <= moment(dateRange[1]) && moment(event.end_date) >= moment(dateRange[0]))
    }, [events, dateRange])

    const filledAndFilteredForwardingOffPlans = useMemo(() => {
        return forwardingOffPlans.map(plan => {
            const start = moment(plan.data[0]['x']).format("YYYY-MM-DD")
            const end = moment(plan.data[1]['x']).format("YYYY-MM-DD")
            const data = {}
            data[start] = 0
            data[end] = 0
            return {
                ...plan,
                data: fillMissingDays(data, 0)
            }
        }).filter(plan => moment(plan.data[0]['x']) <= moment(dateRange[1]) && moment(plan.data[0]['x']) >= moment(dateRange[0]))
    }, [forwardingOffPlans, dateRange])

    const processedData = [{
        "id": "過去納品数",
        "color": "hsla(100, 77%, 44%, 1)",
        "data": historicalData
    }, {
        "id": "予測納品数",
        "color": "hsla(209, 100%, 55%, 1)",
        "data": predictionsData
    },
    ...filledAndFilteredCampaigns,
    ...filledAndFilteredEvents,
    ...filledAndFilteredForwardingOffPlans,
    {
        "id": " ",
        "color": "rgba(0,0,0,0)",
        "data": []
    },
    ];
    
    const makeBarChartData = (data) => {
        const barChartData = []
        data.forEach((dataset) => {
            dataset.data.forEach((point) => {
                const date = point.x
                const existingData = barChartData.find((data) => data.name === date)
                if (existingData) {
                    existingData[dataset.id] = point.y
                } else {
                    const newData = {name: date}
                    newData[dataset.id] = point.y
                    barChartData.push(newData)
                }
            })
        })
        return barChartData
    }
    
    return barChart ? (
		<ResponsiveContainer width="100%" height="100%">
			<BarChart
				width={500}
				height={300}
				data={makeBarChartData(fillData(dateRange, processedData))}
				margin={{
					top: 20,
					right: 30,
					left: 20,
					bottom: 5,
				}}>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="name" />
				<YAxis />
				<Tooltip />
				<Legend />
				<Bar dataKey="過去納品数" stackId="a" fill="#82ca9d" />
				<Bar dataKey="予測納品数" stackId="a" fill="#8884d8"  />
			</BarChart>
		</ResponsiveContainer>
	) : (
		<ResponsiveLine
			data={fillData(dateRange, processedData)}
			margin={{ top: 50, right: 110, bottom: 110, left: 60 }}
			xScale={{
				type: "time",
				format: "%Y-%m-%d",
				useUTC: false,
				precision: "day",
				min: dateRange ? moment(dateRange[0]).format("YYYY-MM-DD") : "auto",
				max: dateRange ? moment(dateRange[1]).format("YYYY-MM-DD") : "auto",
			}}
			yScale={{
				type: "linear",
				min: "auto",
				max: "auto",
				stacked: false,
				reverse: false,
			}}
			yFormat={(v) => (v ? v : "")}
			xFormat="time:%Y-%m-%d"
			axisTop={null}
			axisRight={null}
			axisBottom={{
				format: "%Y-%m-%d",
				legend: "日付",
				legendOffset: -12,
				tickRotation: 60,
				tickValues: numberOfDays(dateRange) < 9 ? "every day" : !tickValues ? 10 : "every " + tickValues,
			}}
			axisLeft={{
				orient: "left",
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legend: "数量(ケース数)",
				legendOffset: -50,
				legendPosition: "middle",
			}}
			enablePoints={false}
			enableSlices={"x"}
			useMesh={true}
			colors={{ datum: "color" }}
		/>
	);
}

export default PredictionsChart;
